const zh = {
    login: {
        login: '登录',
        loginAccount: '登录账号',
        rememberMe: '记住我',
        enterPassword:"请输入密码",
        passwordValidate:"密码错误",
        thirdLogin:'第三方登录',
        loginSuccess:'登录成功'

    },
    register: {
        register: "注册",
        registerAccount: "注册账号",
        registerSuccess:'注册成功',
        username: '用户名',
        password: '密码',
        firstName: '姓',
        lastName: '名',
        confirmPassword: '确认密码',
        usernameEmpty:'用户名不能为空',
        usernameLength:'用户名不能超过128个字符',
        passwordEmpty:'密码不能为空',
        passwordLength:'密码长度为8到20字符',
        firstNameEmpty:'姓不能为空',
        lastNameEmpty:'名不能为空',
        confirmPasswordEmpty:'确认密码不能为空',
        passwordNotEq:'确认密码与密码不符'

    },
    authorize:{
        authorize: "认证",
        preformText:"希望代表您执行一些操作",
        authorizeText:"您是否授权给",
        loginTip:'请先登录',
        lostRedirectAddress:'缺少重定向地址',
        allow:'允许',
        deny:'拒绝'

    }
}

export default zh