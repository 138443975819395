import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import { i18n, useI18n } from './js/i18n/lang'
import locale from '../node_modules/element-ui/lib/locale/lang/zh-CN'
import ElementUI from 'element-ui'
import axios from 'axios'
Vue.use(ElementUI, { locale })
Vue.config.productionTip = false
Vue.prototype.$axios = axios
useI18n(Vue)
new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
