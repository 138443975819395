const fan = {

    login: {
        login: '登錄',
        loginAccount: '登錄帳號',
        rememberMe: '記住我',
        enterPassword: "請輸入密碼",
        passwordValidate: "密碼錯誤",
        thirdLogin:"第三方登录"
    },
    register: {
        register: "註冊",
        registerAccount: "註冊帳號",
        username: '用戶名',
        password: '密碼',
        firstName: '姓氏',
        lastName: '名字',
        confirmPassword: '確認密碼',
        usernameEmpty:'用戶名不能為空',
        usernameLength:'用戶名不能超過128個字符',
        passwordEmpty:'密碼不能為空',
        passwordLength:'密碼長度為8到20字符',
        firstNameEmpty:'姓氏不能為空',
        lastNameEmpty:'名字不能為空',
        confirmPasswordEmpty:'確認密碼不能為空',
        passwordNotEq:'確認密碼與密碼不符'
    },
    authorize:{
        authorize: "認證",
        preformText:"希望代表您執行一些操作",
        authorizeText:"您是否授權給",
        loginTip:'請先登錄',
        lostRedirectAddress:'缺少重定向地址',
        allow:'允許',
        deny:'拒絕'

    }

}

export default fan