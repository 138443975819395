const en = {
    login: {
        login: 'Login',
        loginAccount: 'Login Account',
        rememberMe: 'Remember Me',
        enterPassword: "Please enter password",
        passwordValidate: "Incorrect password",
        thirdLogin:"Third Login"
    },
    register: {
        register: "Register",
        registerAccount: "Register Account",
        username: 'Username',
        password: 'Password',
        firstName: 'First Name',
        lastName: 'Last Name',
        confirmPassword: 'Confirm Password',
        usernameEmpty:'Username cannot be empty',
        usernameLength:'Username cannot be more than 128 characters',
        passwordEmpty:'Password cannot be empty',
        passwordLength:'Password length should between 8 to 20 characters',
        firstNameEmpty:'First name cannot be empty',
        lastNameEmpty:'Last name cannot be empty',
        confirmPasswordEmpty:'Confirm Password cannot be empty',
        passwordNotEq:"Password doesn't match"
    },
    authorize:{
        authorize: "Authorize",
        preformText:"would like to perform actions on your behalf.",
        authorizeText:"Do you want to authorize",
        loginTip:'Please login first',
        lostRedirectAddress:'Missing redirect address',
        allow:'Allow',
        deny:'Deny'
    }
}

export default en